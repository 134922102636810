<template>
  <v-skeleton-loader
    type="image"
    class="risk"
    v-if="!document"
  />
  <v-card
    class="risk"
    v-else
  >
    <v-card-title>
      {{$t('t.Risk')}}
      <a
        v-if="$store.getters.currentUserHasPermission('FinancialInformation')"
        :href='document.websiteUrl'
        target="_blank"
        class="ml-8"
      >
        <v-img
          max-height=32
          max-width=200
          contain
          position="left center"
          :src="logoExternalRisk"
        />
      </a>
      <v-img
        v-else
        max-height=32
        max-width=200
        contain
        position="left center"
        :src="logoExternalRisk"
        class="ml-8"
      />
      <span class="ml-5 subtitle-2 error--text">{{$t(document.riskError)}}</span>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="2">
          <div class="d-flex align-start">
            <v-icon
              large
              :color="color"
            >{{$icon('i.AlertCircleOutline')}}</v-icon>
            <v-text-field
              class="ml-1"
              :label="$t('t.Category')"
              :value="document.name"
              readonly
            />
          </div>
        </v-col>
        <v-col
          cols="2"
          v-if="document.creditScoreInternal"
        >
          <v-text-field
            :label="$t('t.InternalCreditScore')"
            readonly
            :value="document.creditScoreInternal"
          />
        </v-col>
        <v-col
          cols="2"
          v-if="isInfoLegale && !isNaN(document.creditLimitExternal)"
        >
          <amount-field
            :label="$t('t.CreditLimitInfoLegale')"
            :currency="document.creditLimitExternalCurrency"
            :amount="document.creditLimitExternal"
            :empty-value="0"
            readonly
          />
        </v-col>
        <v-col
          cols="2"
          v-if="isCreditSafe && !isNaN(document.creditLimitExternalOld)"
        >
          <amount-field
            :label="$t('t.CreditLimitOld')"
            :currency="document.creditLimitExternalCurrency"
            :amount="document.creditLimitExternalOld"
            :empty-value="0"
            readonly
          />
        </v-col>
        <v-col
          cols="2"
          v-if="isCreditSafe && !isNaN(document.creditLimitExternal)"
        >
          <amount-field
            :label="$t('t.CreditLimit')"
            :currency="document.creditLimitExternalCurrency"
            :amount="document.creditLimitExternal"
            :empty-value="0"
            readonly
          />
        </v-col>
        <v-col
          cols="2"
          v-if="document.status"
        >
          <v-text-field
            :label="$t('t.Status')"
            :value="document.status"
            readonly
          />
        </v-col>
        <!-- <v-col
          cols="2"
          v-if="externalRisk.ellisphereCreditLimit"
        >
          <amount-field
            :currency="account.currency"
            :label="$t('t.CreditLimit')"
            :amount="externalRisk.ellisphereCreditLimit"
            :empty-value="0"
            readonly
          />
        </v-col> -->
        <!-- <v-col
          cols="2"
          v-if="externalRisk.ellisphereActivitySector"
        >
          <v-text-field
            :label="$t('t.ActivitySector')"
            :value="externalRisk.ellisphereActivitySector"
            readonly
          />
        </v-col> -->
        <!-- <v-col
          cols="2"
          v-if="externalRisk.ellisphereSocialSecurityPrivileges"
        >
          <v-text-field
            :label="$t('t.SocialSecurityPrivileges')"
            :value="externalRisk.ellisphereSocialSecurityPrivileges"
            readonly
          />
        </v-col> -->
        <!-- <v-col
          cols="2"
          v-if="externalRisk.ellisphereTreasuryTaxPrivileges"
        >
          <v-text-field
            :label="$t('t.TreasuryTaxPrivileges')"
            :value="externalRisk.ellisphereTreasuryTaxPrivileges"
            readonly
          />
        </v-col> -->
        <v-col
          cols="2"
          v-if="document.infoLegaleSummary"
        >
          <v-text-field
            :label="$t('t.CollectionSummary')"
            :value="document.infoLegaleSummary"
            readonly
          />
        </v-col>
        <v-col
          cols="2"
          v-if="riskScoreOld"
        >
          <v-text-field
            :label="$t('t.CreditScoreOld')"
            :value="riskScoreOld"
            readonly
          />
        </v-col>
        <v-col
          cols="2"
          v-if="riskScore"
        >
          <v-text-field
            :label="$t('t.CreditScore')"
            :value="riskScore"
            readonly
          />
        </v-col>
        <v-col
          cols="2"
          v-if="document.creditSafeLatestRatingChangeDate"
        >
          <v-text-field
            :label="$t('t.LatestRatingChangeDate')"
            :value="formatDate(document.creditSafeLatestRatingChangeDate)"
            readonly
          />
        </v-col>
        <!-- <v-col
          cols="2"
          v-if="externalRisk.ellisphereLastUpdate"
        >
          <v-text-field
            :label="$t('t.LatestRatingChangeDate')"
            :value="formatDate(externalRisk.ellisphereLastUpdate)"
            readonly
          />
        </v-col> -->
        <!-- <v-col
          cols="2"
          v-if="externalRisk.ellisphereSectorialScore"
        >
          <v-text-field
            :label="$t('t.CreditScoreSectorial')"
            :value="externalRisk.ellisphereSectorialScore"
            readonly
          />
        </v-col> -->
        <v-col
          cols="2"
          v-if="document.creditSafeDetailCreditScore"
        >
          <v-text-field
            :label="$t('t.DetailCreditScore')"
            :value="document.creditSafeDetailCreditScore"
            readonly
          />
        </v-col>
        <v-col cols="2">
          <amount-field
            :label="$t('t.CreditLimitInternal')"
            :currency.sync="currency"
            :amount.sync="creditLimitComputed"
            :empty-value="0"
            :readonly="!$store.getters.currentUserHasPermission('AccountCanChangeCreditLimit')"
          />
        </v-col>
        <v-col
          cols="2"
          v-if="!isNaN(document.creditLimitConsolidated) && document.creditLimitConsolidated !== creditLimit"
        >
          <amount-field
            :label="$t('t.CreditLimitInternalConsolidated')"
            :currency.sync="currency"
            :amount.sync="document.creditLimitConsolidated"
            :empty-value="0"
            readonly
          />
        </v-col>
        <v-col
          cols="2"
          v-if="document.creditUsage"
        >
          <amount-field
            :label="$t('t.CreditUsage')"
            :currency.sync="currency"
            :amount="document.creditUsage"
            :empty-value="0"
            readonly
          />
        </v-col>
        <v-col
          cols="2"
          v-if="document.creditUsagePercent"
        >
          <v-text-field
            :label="$t('t.CreditUsagePercent')"
            :value="document.creditUsagePercent | percent"
            readonly
          />
        </v-col>
        <v-col
          cols="2"
          v-if="document.maxCredit"
        >
          <amount-field
            :label="$t('t.HighCredit12M')"
            :currency.sync="currency"
            :amount="document.maxCredit"
            :empty-value="0"
            readonly
          />
        </v-col>
        <v-col
          cols="2"
          v-if="document.creditHoldReason"
        >
          <v-text-field
            :label="$t('t.CreditHoldReason')"
            v-model="document.creditHoldReason"
          />
        </v-col>
        <v-col
          cols="2"
          v-if="document.provisionDay"
        >
          <amount-field
            :label="$t('t.Provision')"
            :currency.sync="$store.getters.currentUser.systemCurrency"
            :amount="document.provisionDay"
            :empty-value="0"
            readonly
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import DocumentEditor from '@/mixins/document-editor'

export default {
  mixins: [DocumentEditor],
  components: {
    AmountField: () => import('@/components/amount-field')
  },
  data () {
    return {
      creditLimitData: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)

    }
  },
  computed: {
    cacheType () {
      return DocumentEditor.CacheType.AccountRiskCategoryDetail
    },
    id () {
      return this.accountId
    },
    creditLimitComputed: {
      get () {
        return this.creditLimitData
      },
      set (creditLimit) {
        this.creditLimitData = creditLimit
        this.dataDocumentEmit()
      }
    },
    color () {
      return `#${this.document?.color}`
    },
    riskCategoryType () {
      return this.document?.type
    },
    riskScoreOld () {
      return this.document?.previousRiskScoreInternational ? `${this.document?.previousRiskScoreInternational} (${this.document?.previousRiskScore})` : this.document?.previousRiskScore
    },
    riskScore () {
      return this.document?.riskScoreInternational ? `${this.document?.riskScoreInternational} (${this.document?.riskScore})` : this.document?.riskScore
    },
    isInfoLegale () {
      return this.riskCategoryType === 2
    },
    isCreditSafe () {
      return this.riskCategoryType === 3
    },
    logoExternalRisk () {
      switch (this.riskCategoryType) {
        case 2:
          return require('@/assets/LogoInfoLegal.png')
        case 3:
          return require('@/assets/LogoCreditSafe.jpg')
        default:
          return ''
      }
    }
  },
  methods: {
    emitDocument () {
      if (this.creditLimit !== this.creditLimitData) {
        this.$emit('update:credit-limit', this.creditLimitData)
      }
    }
  },
  props: {
    accountId: String,
    creditLimit: Number,
    currency: String
  },
  watch: {
    creditLimit: {
      immediate: true,
      handler (v) {
        this.creditLimitData = v
      }
    },
    riskCategoryType (v) {
      if (v) {
        this.$emit('update:risk-category-type', v)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.v-card__text
  display flex
  flex-direction row
</style>
